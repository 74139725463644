import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, HStack, Text } from '@chakra-ui/react';
import { PageProps, graphql } from 'gatsby';

import Disclaimer  from "../components/Sections/Disclaimer"
import Layout from '../components/Base/Layout'
import React from 'react'
import {SvgBackground} from "../components/Base/SvgLogo"

// import { MinusIcon, AddIcon } from '@chakra-ui/icons'



const About: React.FC<FAQ> = ({ data }) => {

  
    
    const {
        wpPage: {
            faq: { title, desc, questionsRepeater, questionsRepeater: [{question, answer}]}
        }, 
    } = data

  return (
      <Layout>
      <Box as="section" maxW="container.xl" minHeight="605px" margin="auto" px='12' py='12' pt="24"  >
        <SvgBackground position="absolute" top="0" right="0" minH="600px" ></SvgBackground>
              <HStack position="relative" minHeight="inherit" flexDirection={{base: "column", md: "row"}} justifyContent={{base: "center", md: "space-between"}} alignItems={{ base: "center", md:'flex-start'}}>
                <Box pb="4">
                    <Text textAlign={{base: "center", md: "left"}} fontSize="lg" fontWeight="900" color='gray.900'>
                        {title}
                    </Text>
                    <Text textAlign={{base: "center", md: "left"}} color='gray.800' dangerouslySetInnerHTML={{ __html: desc }}></Text>
                </Box>
                  <Accordion allowMultiple bg="white" borderRadius="lg" w={{base: "100%",md:"588px"}} p="4" boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06);">
                      {questionsRepeater.map((item) => {
                          return (
                            <AccordionItem>
                                <h2>
                                <AccordionButton fontWeight="900" color="gray.700" _expanded={{ color: 'blue.500' }}>
                                    <Box flex='1' textAlign='left'>
                                    {item.question}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                                </h2>
                                <AccordionPanel pb={4} color="gray.700" dangerouslySetInnerHTML={{ __html: item.answer }}>
                               
                                </AccordionPanel>
                            </AccordionItem>
                          )
                      })}
                </Accordion>
            </HStack>
           </Box>
   
          {/* DISCLAIMER SECTION */}
      <Disclaimer></Disclaimer>
      
    </Layout>
  )
}

type FAQ = PageProps<FAQProps>

type FAQProps = {
    wpPage: {
        faq: {
            title: string,
            desc: string, 
            questionsRepeater: [
                {
                    question: string, 
                    answer: string
                }
            ]
        }
    }
}

export const query = graphql`
  {
    wpPage(slug: {eq: "faq"}) {
      faq {
        desc
        title
        questionsRepeater {
          question
          answer
        }
      }
    }
  }
`
export default About