import {
    chakra, 
    HTMLChakraProps,
    IconProps,
    Icon,
    useBreakpointValue,
} from '@chakra-ui/react'
import * as React from 'react'

export const SvgBackground = (props: IconProps) => {
    return (
    <Icon
    viewBox="0 0 585 588" 
    height="588"
    width="585"
    fill="#313947" 
    opacity="0.1"
    xmlns="http://www.w3.org/2000/svg" {...props}>
            <path xmlns="http://www.w3.org/2000/svg" d="M25.304 562.566c55.243 55.515 220.05-19.782 367.376-167.872C540.017 246.61 614.937 80.959 559.696 25.434 504.451-30.1 339.636 45.22 192.317 193.3 44.993 341.382-29.919 507.031 25.304 562.566ZM76.182 588c-23.646 0-42.979-6.39-56.402-19.882-58.286-58.586 16.636-229.216 167.013-380.369C337.167 36.606 506.92-38.702 565.221 19.879c58.285 58.592-16.639 229.222-167.016 380.37C282.459 516.591 155.225 588 76.182 588Z" />
            <path xmlns="http://www.w3.org/2000/svg" d="M583.826 402.744c-162.664 11.223-171.765 20.323-182.995 182.892-11.229-162.569-20.334-171.669-182.995-182.892 162.661-11.223 171.766-20.322 182.995-182.892 11.23 162.57 20.331 171.669 182.995 182.892Z"/>
<path d="M25.304 562.566c55.243 55.515 220.05-19.782 367.376-167.872C540.017 246.61 614.937 80.959 559.696 25.434 504.451-30.1 339.636 45.22 192.317 193.3 44.993 341.382-29.919 507.031 25.304 562.566ZM76.182 588c-23.646 0-42.979-6.39-56.402-19.882-58.286-58.586 16.636-229.216 167.013-380.369C337.167 36.606 506.92-38.702 565.221 19.879c58.285 58.592-16.639 229.222-167.016 380.37C282.459 516.591 155.225 588 76.182 588Z"/><path d="M583.826 402.744c-162.664 11.223-171.765 20.323-182.995 182.892-11.229-162.569-20.334-171.669-182.995-182.892 162.661-11.223 171.766-20.322 182.995-182.892 11.23 162.57 20.331 171.669 182.995 182.892Z"/>
    </Icon>
    );
};